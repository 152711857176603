html {
    overflow: hidden;
}
html,
body,
#root,
#root > div {
    margin: 0;
    height: 100%;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}
input[type='number']:hover,
input[type='number']:focus {
    -moz-appearance: number-input;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}
::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    background: #aaaaaa;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #bbbbbb;
    
}
