.contribution-table, .date-of-birth-table{
    border-spacing: 0;
    font-size: 12px;
    border-radius: 2px;
}

.contribution-table td, .contribution-table th, .date-of-birth-table td, .date-of-birth-table th {
    border: 1px solid;
    border-spacing: 0;
    padding: 6px;
}

.contribution-table .text-left, .date-of-birth-table .text-left{
    text-align: left;
}